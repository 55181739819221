import React, { Suspense } from 'react';
import NavBar from "../Components/NavBar/NavBar"
import Term from "../Components/Terms/Term"
import { Helmet } from "react-helmet";

import '../app/styles.scss';
import icon_white from '../../static/favicon_white.ico';
import icon_blue from '../../static/favicon_blue.ico';

export default function Layout({ children }) {
  // const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
  // let isDark = prefersDarkScheme.matches;
  let isDark = false;

  return (
    <>
      <Helmet>
        <title>NowCaptain - Εξερευνήστε την ακτογραμμή !!</title>
        {isDark ? <link rel="icon" href={icon_white} id="light-scheme-icon" /> :
        <link rel="icon" href={icon_blue} id="dark-scheme-icon" />}
      </Helmet>

      {/* <Suspense fallback={null}> */}
      <div className="app" id="container">
        <NavBar id="header" />
        <main id="body"> {/* make this responsive avoide hide content */}
          {children}
        </main>
        <Term id="footer" />
      </div>
      {/* </Suspense> */}
    </>
  )
}