import React from 'react';
import { Link } from "gatsby"

import './styles.scss';

import logo_hat_blue from '../../../Assets/logo_hat_blue.svg';
import logo_text_blue from '../../../Assets/logo_text_blue.svg';
import logo_hat_white from '../../../Assets/logo_hat_white.svg';
import logo_text_white from '../../../Assets/logo_text_white.svg';

const Title = ({scroll}) => {
    return (
        <>
            <div className="logo">
                <Link to="/">
                    <img className="logo-hat" src={scroll ? logo_hat_white : logo_hat_blue} />
                    <img className="logo-text" src={scroll ? logo_text_white : logo_text_blue} />
                </Link>
            </div>
        </>
    )
}

export default Title;
// style={{visibility: scroll ? 'hidden' : 'visible'}}