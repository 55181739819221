import React from 'react';
import { Link } from "gatsby"

import './styles.scss';

const About = () => {
  return (
    <>
      <section className="about">
        <div className="content">
          <h2 className="text-center title">Σχετικά με εμάς</h2>
            <div className="paragraph">
              <p className="text-justify text-monospace p-3 text-center">Ένα μεγάλο ποσοστό από σημεία οπως παραλίες, μικρά ακατοίκητα νησάκια με εντυπωσιακές παραλίες ή και σημεία με ιδιαίτερο ενδιαφέρον για scuba diving στην Ελλαδα αλλα και στην ευρύτερη περιοχή της Μεσογείου δεν είναι προσβάσιμες με κάποια από τα συμβατικά μέσα από την στεριά, παρα με μόνο τρόπο την μετάβαση μέσω από τη θάλασσα με αποτέλεσμα να χάνουμε αυτα τα φανταστικά μέρη.</p>
              <p className="text-justify text-monospace p-3 text-center">Το nowCaptain θα είναι μια πλατφόρμα που αυτο που κάνει είναι, να συνδέει τους ταξιδιώτες που είναι σε ένα μέρος (πχ. νησί) οι οποιοι θέλουν να επισκεφτούν μία δυσπρόσιτη παραλία του νησιού, με τους ντόπιους ιδιοκτήτες σκαφών ιδιώτες/επαγγελματίες.</p>
              <p className="text-justify text-monospace p-3 text-center">Και το μόνο που έχουν να κάνουν οι ταξιδιώτες είναι να επιλέξουν όποιον καλύπτει τις ανάγκες του, βάση της τιμής, των αξιολογήσεων από προηγούμενους χρήστες και να μεταβούν στο σημείο συνάντησης.</p>
            </div>
          </div>
        <div className="ocean">
          <div className="wave"></div>
          <div className="wave"></div>
        </div>
      </section>
    </>
  )
}

export default About;