import React from 'react';
import {Link as LinkPage} from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import { Link, animateScroll as scroll } from "react-scroll";

import About from "../../../pages/about";

import './styles.scss';

const NavLink = () => {
    return (
        <>
            <ul className="links-bar">
                <li className="d-inline list"><AnchorLink className="link-nav" to="/#how-works" >Πώς λειτουργεί;</AnchorLink></li>
                <li className="d-inline list"><LinkPage className="link-nav" to="/about">Σχετικά με εμάς</LinkPage></li>
                <li className="d-inline list"><AnchorLink className="link-nav" to="/#contact" >Επικοινωνία</AnchorLink></li>
            </ul>
        </>
    )
}

export default NavLink;