import React from 'react';
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Social from '../Social/Social'

import { isMobileOnly } from 'react-device-detect';

import './styles.scss';

import logo_hat_white from '../../Assets/logo_hat_white.svg';
import logo_text_white from '../../Assets/logo_text_white.svg';

import lang_icon_white from '../../Assets/language-white.svg';
import love_icon from '../../Assets/love_icon.svg'

const Footer = () => {
    return (
        <>
            <footer className="container-fluid footer">
                <div className="row">
                    <div className="col-md-3">
                        <div className="footer-list">
                            <h3 className="list-title">Σχετικά</h3>
                            <ul className="list">
                                <li><AnchorLink className="link" to="/#how-works">Πώς το nowCaptain λειτουργεί;</AnchorLink></li>
                                <li><Link className="link" to="/about">Ποιοι είμαστε</Link></li>
                                {/*<li><Link className="link" to="#">Blog</Link></li>*/}
                                {/*<li><Link className="link" to="#">Terms of Use</Link></li>*/}
                                <li><Link className="link" to="/privacy-policy">Πολιτική απορρήτου</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="footer-list">
                            <h3 className="list-title">Newsroom</h3>
                            <ul className="list">
                                <li><Link className="link" to="/become-captain">Γίνετε καπετάνιος</Link></li>
                                {/*<li><Link className="link" to="#">Partners</Link></li>*/}
                                <li><Link className="link" to="/newsroom">Νέα</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6">
                        { isMobileOnly ? null : <Link className="link" to="/">
                            <div className="logo-footer">
                                <img className="logo-footer-hat" src={logo_hat_white} />
                                <img className="logo-footer-text" src={logo_text_white} />
                            </div>
                        </Link>}
                        <div className="d-block">
                            {/*<div className="lang-btn">*/}
                            {/*    <img src={lang_icon_white} />*/}
                            {/*    <p>Ελληνικά (GR)</p>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                <div className="row footer-end">
                    <div className="col-md-4">
                        <p className="copyright">{(new Date().getFullYear())} © NOWCAPTAIN. ALL RIGHTS RESERVED.</p>
                    </div>
                    <div className="col-md-4">
                        <p class="made-with-love">Made with <img src={love_icon} alt='Love icon' /> in Patras, Greece</p>
                    </div>
                    <div className="col-md-4">
                         {/*<p className="social-text">Follow <span className="blue-text">nowCaptain</span></p>*/}
                        <Social />
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;