import React, { useEffect, Component } from "react";
import { Link } from "gatsby"
import { graphql } from 'gatsby'

import Layout from "../Components/layout"
import AboutUs from "../Components/About/About"
import Footer from "../Components/Footer/Footer"
// import SEO from "../components/seo"

class About extends Component {
  render() {
    // const { data } = this.props
    // const projects = data;
    // const menu = data.site.siteMetadata.menuLinks;
    // console.log(menu);
    // .allContentJson.edges[0].node.allProjects;
    return (
      <div>
        <Layout>
          {/* <SEO title="Home" /> */}
          <AboutUs/>
          <Footer/>
        </Layout>
      </div>
    )
  }
}

export default About