import React from 'react';
// import { GatsbyLink } from "gatsby"
import Link from './Link/Link'
import Titlle from './Title/Title'
import Social from '../Social/Social'
import Lang from '../Language/Lang'

import './styles.scss';

class Nav extends React.Component {
    constructor(props) {
        super(props);
        this.state = { scroll: false, menu: false };
    }
    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        if (window.scrollY > 100) {
            this.setState({scroll: true});
            var elems = document.querySelectorAll(".link-nav");
            [].forEach.call(elems, function (el) {
                el.classList.add("link-nav-scroll");
            });
            document.querySelector(".nav-head").className = "navbar navbar-expand-lg navbar-light-scroll nav-head scroll fixed-top";
            document.querySelector(".navbar-toggler-icon").className = "navbar-toggler-icon navbar-toggler-icon-scroll";
            document.querySelector(".social-icons").className = "social-icons social-icons-scroll";
        } else {
            this.setState({scroll: false});
            var elems = document.querySelectorAll(".link-nav");
            [].forEach.call(elems, function (el) {
                el.classList.remove("link-nav-scroll");
            });
            document.querySelector(".nav-head").className = "navbar navbar-expand-lg navbar-light nav-head fixed-top";
            document.querySelector(".navbar-toggler-icon").className = "navbar-toggler-icon";
            document.querySelector(".social-icons").className = "social-icons";
        }
    };

    openMenu = () => {
        this.setState({menu: !this.state.menu});
    }

    render() {
        return (
            <>
                {/* <nav className="navbar navbar-expand-lg nav-head fixed-top"> 
                    <div className="container-fluid">
                        <div className="row no-margin-nav">
                            <div className="col-md-5" style={{ margin: 'auto' }}>
                                <Titlle scroll={this.state.scroll}/>
                            </div>
                            <div className="col-md-5" style={{ margin: 'auto' }}>
                                <Link />
                            </div>
                            <div className="col social" style={{ margin: 'auto' }}>
                                <Social scroll={this.state.scroll}/>
                            </div>
                        </div>
                    </div>
                </nav> */}
                <nav class="navbar navbar-expand-md navbar-light nav-head fixed-top">
                    <div className="navbar-brand">
                        <Titlle scroll={this.state.scroll} />
                    </div>
                    <button id="nav-toggle-button" className={this.state.scroll ? !this.state.menu ? "navbar-toggler navbar-toggler-scroll navbar-toggler-white-scroll" : "navbar-toggler navbar-toggler-scroll navbar-toggler-white-close" : !this.state.menu ? "navbar-toggler navbar-toggler-scroll navbar-toggler-blue-scroll" : "navbar-toggler navbar-toggler-scroll navbar-toggler-blue-close"} type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation" onClick={this.openMenu}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="container-fluid">
                        <div class="row collapse navbar-collapse" id="navbarNavAltMarkup">
                            <div className="col-md-10 nav-item">
                                <Link />
                            </div>
                            <div className="col-md-2 nav-item">
                                <Social scroll={this.state.scroll} />
                                {/*<Lang scroll={this.state.scroll}/>*/}
                            </div>
                        </div>
                    </div>
                </nav>

            </>
        )
    }
}

export default Nav;