import React from 'react';
import { Link } from "gatsby"

import './styles.scss';

import f_icon_color from '../../Assets/facebook_icon_color.png';
import f_icon_black from '../../Assets/facebook_icon_black.png';
import insta_icon_color from '../../Assets/instagram_icon_color.png';
import insta_icon_black from '../../Assets/instagram_icon_black.png';

const Social = ({ scroll }) => {
  let fb_url = "https://www.facebook.com/nowCaptain";
  let ig_url = "https://www.instagram.com/nowcaptain";
    return (
        <>
            <div className="social-icons nav-item">
                <p className="text-center nav-social-text">Ακολούθηστε το <span className={scroll ? "span-scroll" : "span-no-scroll"}>nowCaptain</span></p>
                <a href={fb_url} target="_blank"><img className="social-icon" src={scroll ? f_icon_black : f_icon_color} alt="Ακολούθησε στο Facebook" /></a>
                <a href={ig_url} target="_blank"><img className="social-icon" src={scroll ? insta_icon_black : insta_icon_color} alt="Ακολούθησε στο Instagram" /></a>
            </div>
        </>
    )
}

export default Social;