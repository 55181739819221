import React from 'react';
import Cookies from 'js-cookie';

import { Link as LinkPage } from "gatsby"

import './styles.scss';


export default class Term extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cookie: Cookies.get('nc-cookie'),
        };
        this.setCookie = this.setCookie.bind(this);
    }

    setCookie() {

        console.log(Cookies.get('nc-cookie'))
        Cookies.set('nc-cookie', 'false', { expires: 180 })
        console.log(Cookies.get('nc-cookie'))
        this.setState({
            cookie: Cookies.get('nc-cookie'),
        });
    }

    componentDidMount() {
        const body = document.querySelector("#body");
        if (this.state.cookie == true || this.state.cookie == undefined) {
            body.style.paddingBottom = '60px';
        } else {
            body.style.paddingBottom = '0px'
        }
    }

    render() {
        return (
          <>
              <div>
              {this.state.cookie == true || this.state.cookie == undefined ?
              <div className="fixed-bottom cookies">
                  <div className="container-fluid">
                      <p>Χρησιμοποιούμε cookies <span role="img" aria-label="cookie">🍪</span> για να σας παρέχουμε την καλύτερη εμπειρία. Συνεχίζοντας να χρησιμοποιείτε τον ιστότοπό μας, αποδέχεστε την πολιτική μας για τα cookies.</p>
                      <div className="row">
                          <div className="col">
                              <button type="button" class="btn learn"><LinkPage className="link-nav" to="/privacy-policy">Μάθετε περισσότερα</LinkPage></button>
                          </div>
                          <div className="col">
                              <button type="button" class="btn got-it" onClick={this.setCookie}>Το έπιασα</button>
                          </div>
                      </div>
                  </div>
              </div>
                : null}
              </div>
          </>
        )
    }
}
